// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-administration-js": () => import("./../../../src/pages/administration.js" /* webpackChunkName: "component---src-pages-administration-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-compliance-management-auditing-and-monitoring-js": () => import("./../../../src/pages/compliance-management-auditing-and-monitoring.js" /* webpackChunkName: "component---src-pages-compliance-management-auditing-and-monitoring-js" */),
  "component---src-pages-demo-request-js": () => import("./../../../src/pages/demo-request.js" /* webpackChunkName: "component---src-pages-demo-request-js" */),
  "component---src-pages-energy-and-utilities-sector-cyber-security-js": () => import("./../../../src/pages/energy-and-utilities-sector-cyber-security.js" /* webpackChunkName: "component---src-pages-energy-and-utilities-sector-cyber-security-js" */),
  "component---src-pages-features-active-vs-idle-time-analysis-js": () => import("./../../../src/pages/features/active-vs-idle-time-analysis.js" /* webpackChunkName: "component---src-pages-features-active-vs-idle-time-analysis-js" */),
  "component---src-pages-features-application-usage-monitoring-js": () => import("./../../../src/pages/features/application-usage-monitoring.js" /* webpackChunkName: "component---src-pages-features-application-usage-monitoring-js" */),
  "component---src-pages-features-custom-reports-js": () => import("./../../../src/pages/features/custom-reports.js" /* webpackChunkName: "component---src-pages-features-custom-reports-js" */),
  "component---src-pages-features-dlp-features-js": () => import("./../../../src/pages/features/dlp-features.js" /* webpackChunkName: "component---src-pages-features-dlp-features-js" */),
  "component---src-pages-features-e-mail-monitoring-js": () => import("./../../../src/pages/features/e-mail-monitoring.js" /* webpackChunkName: "component---src-pages-features-e-mail-monitoring-js" */),
  "component---src-pages-features-employee-internet-monitoring-js": () => import("./../../../src/pages/features/employee-internet-monitoring.js" /* webpackChunkName: "component---src-pages-features-employee-internet-monitoring-js" */),
  "component---src-pages-features-employee-productivity-tracking-js": () => import("./../../../src/pages/features/employee-productivity-tracking.js" /* webpackChunkName: "component---src-pages-features-employee-productivity-tracking-js" */),
  "component---src-pages-features-file-activity-monitoring-software-js": () => import("./../../../src/pages/features/file-activity-monitoring-software.js" /* webpackChunkName: "component---src-pages-features-file-activity-monitoring-software-js" */),
  "component---src-pages-features-file-scanner-js": () => import("./../../../src/pages/features/file-scanner.js" /* webpackChunkName: "component---src-pages-features-file-scanner-js" */),
  "component---src-pages-features-instant-message-monitoring-js": () => import("./../../../src/pages/features/instant-message-monitoring.js" /* webpackChunkName: "component---src-pages-features-instant-message-monitoring-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-features-keystroke-monitoring-js": () => import("./../../../src/pages/features/keystroke-monitoring.js" /* webpackChunkName: "component---src-pages-features-keystroke-monitoring-js" */),
  "component---src-pages-features-linux-terminal-capture-js": () => import("./../../../src/pages/features/linux-terminal-capture.js" /* webpackChunkName: "component---src-pages-features-linux-terminal-capture-js" */),
  "component---src-pages-features-live-employee-desktop-js": () => import("./../../../src/pages/features/live-employee-desktop.js" /* webpackChunkName: "component---src-pages-features-live-employee-desktop-js" */),
  "component---src-pages-features-network-monitoring-js": () => import("./../../../src/pages/features/network-monitoring.js" /* webpackChunkName: "component---src-pages-features-network-monitoring-js" */),
  "component---src-pages-features-nonproductive-activity-reporting-js": () => import("./../../../src/pages/features/nonproductive-activity-reporting.js" /* webpackChunkName: "component---src-pages-features-nonproductive-activity-reporting-js" */),
  "component---src-pages-features-optical-character-recognition-ocr-js": () => import("./../../../src/pages/features/optical-character-recognition-ocr.js" /* webpackChunkName: "component---src-pages-features-optical-character-recognition-ocr-js" */),
  "component---src-pages-features-printed-document-tracking-js": () => import("./../../../src/pages/features/printed-document-tracking.js" /* webpackChunkName: "component---src-pages-features-printed-document-tracking-js" */),
  "component---src-pages-features-remote-desktop-control-js": () => import("./../../../src/pages/features/remote-desktop-control.js" /* webpackChunkName: "component---src-pages-features-remote-desktop-control-js" */),
  "component---src-pages-features-screen-capture-js": () => import("./../../../src/pages/features/screen-capture.js" /* webpackChunkName: "component---src-pages-features-screen-capture-js" */),
  "component---src-pages-features-session-recording-and-metadata-js": () => import("./../../../src/pages/features/session-recording-and-metadata.js" /* webpackChunkName: "component---src-pages-features-session-recording-and-metadata-js" */),
  "component---src-pages-features-smart-rules-and-alerts-js": () => import("./../../../src/pages/features/smart-rules-and-alerts.js" /* webpackChunkName: "component---src-pages-features-smart-rules-and-alerts-js" */),
  "component---src-pages-features-software-and-hardware-inventory-js": () => import("./../../../src/pages/features/software-and-hardware-inventory.js" /* webpackChunkName: "component---src-pages-features-software-and-hardware-inventory-js" */),
  "component---src-pages-features-usb-device-control-js": () => import("./../../../src/pages/features/usb-device-control.js" /* webpackChunkName: "component---src-pages-features-usb-device-control-js" */),
  "component---src-pages-features-user-card-js": () => import("./../../../src/pages/features/user-card.js" /* webpackChunkName: "component---src-pages-features-user-card-js" */),
  "component---src-pages-features-voice-communication-recording-js": () => import("./../../../src/pages/features/voice-communication-recording.js" /* webpackChunkName: "component---src-pages-features-voice-communication-recording-js" */),
  "component---src-pages-features-webcam-snapshots-js": () => import("./../../../src/pages/features/webcam-snapshots.js" /* webpackChunkName: "component---src-pages-features-webcam-snapshots-js" */),
  "component---src-pages-for-banks-js": () => import("./../../../src/pages/for-banks.js" /* webpackChunkName: "component---src-pages-for-banks-js" */),
  "component---src-pages-for-financial-sector-js": () => import("./../../../src/pages/for-financial-sector.js" /* webpackChunkName: "component---src-pages-for-financial-sector-js" */),
  "component---src-pages-for-hipaa-js": () => import("./../../../src/pages/for-hipaa.js" /* webpackChunkName: "component---src-pages-for-hipaa-js" */),
  "component---src-pages-for-legal-js": () => import("./../../../src/pages/for-legal.js" /* webpackChunkName: "component---src-pages-for-legal-js" */),
  "component---src-pages-gdpr-compliance-js": () => import("./../../../src/pages/gdpr-compliance.js" /* webpackChunkName: "component---src-pages-gdpr-compliance-js" */),
  "component---src-pages-government-and-public-sector-cyber-security-js": () => import("./../../../src/pages/government-and-public-sector-cyber-security.js" /* webpackChunkName: "component---src-pages-government-and-public-sector-cyber-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insider-threat-detection-and-prevention-js": () => import("./../../../src/pages/insider-threat-detection-and-prevention.js" /* webpackChunkName: "component---src-pages-insider-threat-detection-and-prevention-js" */),
  "component---src-pages-iso-27001-compliance-js": () => import("./../../../src/pages/iso-27001-compliance.js" /* webpackChunkName: "component---src-pages-iso-27001-compliance-js" */),
  "component---src-pages-leadership-js": () => import("./../../../src/pages/leadership.js" /* webpackChunkName: "component---src-pages-leadership-js" */),
  "component---src-pages-monitoring-js": () => import("./../../../src/pages/monitoring.js" /* webpackChunkName: "component---src-pages-monitoring-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pci-dss-compliance-and-certification-js": () => import("./../../../src/pages/pci-dss-compliance-and-certification.js" /* webpackChunkName: "component---src-pages-pci-dss-compliance-and-certification-js" */),
  "component---src-pages-privileged-user-monitoring-js": () => import("./../../../src/pages/privileged-user-monitoring.js" /* webpackChunkName: "component---src-pages-privileged-user-monitoring-js" */),
  "component---src-pages-productivity-optimization-js": () => import("./../../../src/pages/productivity-optimization.js" /* webpackChunkName: "component---src-pages-productivity-optimization-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-pages-remote-employee-monitoring-js": () => import("./../../../src/pages/remote-employee-monitoring.js" /* webpackChunkName: "component---src-pages-remote-employee-monitoring-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-retail-and-ecommerce-cyber-security-js": () => import("./../../../src/pages/retail-and-ecommerce-cyber-security.js" /* webpackChunkName: "component---src-pages-retail-and-ecommerce-cyber-security-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-third-party-vendor-management-js": () => import("./../../../src/pages/third-party-vendor-management.js" /* webpackChunkName: "component---src-pages-third-party-vendor-management-js" */),
  "component---src-pages-white-papers-js": () => import("./../../../src/pages/white-papers.js" /* webpackChunkName: "component---src-pages-white-papers-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */)
}

